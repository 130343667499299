import React, { useState } from 'react';
import Swal from 'sweetalert2';
import logo from './assets/logo.png'; // Importa el logo desde la carpeta assets

function App() {
  const [dni, setDni] = useState('');
  const [nombreCompleto, setNombreCompleto] = useState(''); // Cambiamos a nombreCompleto

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Mostrar SweetAlert de progreso inicial
    Swal.fire({
      title: 'Generando credencial...',
      text: 'Por favor, espera mientras procesamos tu solicitud.',
      allowOutsideClick: false,
      showConfirmButton: false, // Esto elimina el botón "OK"

      didOpen: () => {
        Swal.showLoading();
      }
    });

    // Realizar la búsqueda del afiliado antes de generar la credencial
    const searchResponse = await fetch(`/api/get-affiliate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ dni }),
    });

    if (searchResponse.ok) {
      // Si el afiliado se encuentra, obtener los datos y proceder a generar la credencial
      const data = await searchResponse.json();
      setNombreCompleto(data.nombreCompleto); // Establecer el nombre completo directamente

      Swal.update({
        title: 'Descargando credencial...',
        text: 'Por favor, espera mientras se descarga la credencial.',
      });

      const response = await fetch(`/api/generate-credential`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ dni, nombreCompleto: data.nombreCompleto }), // Utilizamos nombreCompleto directamente
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `credencial-${dni}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        Swal.close(); // Cerrar el SweetAlert después de descargar la credencial
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error al generar la credencial',
          text: 'Ocurrió un problema al intentar generar la credencial. Por favor, intenta nuevamente.',
        });
      }
    } else {
      // Si el afiliado no se encuentra, mostrar un mensaje de error
      Swal.fire({
        icon: 'error',
        title: 'Afiliado no encontrado',
        text: 'Por favor, verifica el DNI ingresado.',
      });
      console.error('Afiliado no encontrado');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg w-full max-w-xs sm:max-w-md">
        <div className="text-center mb-4 sm:mb-6">
          <img
            src={logo} // Usa el logo importado
            alt="Logo de la empresa"
            className="mx-auto h-12"
          />
        </div>
        <h1 className="text-xl sm:text-2xl font-bold text-center mb-4 sm:mb-6">Generá tu credencial</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex items-center space-x-2">
            <label className="block text-gray-700">DNI:</label>
            <input
              type="text"
              value={dni}
              onChange={(e) => setDni(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
          >
            Generar Credencial
          </button>
        </form>
      </div>
    </div>
  );
}

export default App;